<!--电能设置--基本设置---->
<template>
    <div id="energyAnalysisBuildData" ref="" v-loading="loading" :element-loading-text="$t('alarm.a0')">
        <!-- 页面标题 -->
        <div class="header-title">
            <span>{{ $t("energy.enedata064") }}</span>
        </div>

        <div class="header">
            <div class="header-left">
                <div class="header-condition">
                    <span>{{ $t("energy.enedata012") }}</span>
                    <a-radio-group v-model="rpt" button-style="solid" @change="rptChange">
                        <a-radio-button  v-for="(itme, index) in this.eneKindList"
                            :key="index"
                            :value="itme.no"> {{ itme.text }}</a-radio-button>
                    </a-radio-group>
                </div>
            
               

                <div class="header-condition" >
                    <span>{{ $t("energy.enedata020") }}</span>
                    <a-select v-model="basicUnit" style="width: 200px" @change="basicUnitChange">
                        <a-select-option
                            v-for="(itme, index) in this.basicUnitList"
                            :key="index"
                            :value="itme.no"
                            >
                            {{ itme.text }}
                        </a-select-option>
                    </a-select>
                </div>

                 <div class="header-condition">
                    <span>{{ $t("energy.enedata017") }}</span>
                    <div>
                        <a-month-picker
                            format="YYYY/MM"
                            v-model="dateFrom"
                            :disabled-date="disabledStartDate"
                            @change="handleSMonthChange"
                        />
                        ~
                        <a-month-picker
                            format="YYYY/MM"
                            v-model="dateTo"
                            :disabled-date="disabledEndDate"
                            @change="handleEMonthChange"
                        />
                    </div>
                    
                </div>

                <div class="header-condition">
                    <span>{{ $t("energy.enedata075") }}</span>
                    <a-radio-group v-model="kind" button-style="solid" @change="kindChanges">
                        <a-radio-button value="1">{{ $t("energy.enedata076") }}</a-radio-button>
                        <a-radio-button value="2">{{ $t("energy.enedata077") }}</a-radio-button>
                        <a-radio-button value="3">{{ $t("energy.enedata078") }}</a-radio-button>
                    </a-radio-group>
                </div>
            </div>
            <div>
                <a-button  type="primary"   @click="search()"> {{ $t("energy.enedata006") }} </a-button>
                <a-button  type="primary"   @click="csvout()"> {{ $t("energy.enedata042") }} </a-button>
            </div>
        </div>


        <div class="main">
            
            
            <a-table :style="{width: '100%', height: '300px',padding:'10px'}"
                :columns="columns"
                :data-source="data"
                :pagination="pagination"
                :scroll="xscroll"
                size="small"
            >
            </a-table>
                       
        </div> 
   
    </div>
</template>



<script>
import { getEnergyAnalysisBuildData,downloadEnergyCSVDataWithURL } from "../../../api/energy";
import { downloadUrl } from "../../../utils/utils";
import moment from "moment";



export default {
  name: "energyAnalysisBuildData",
  data() {
    
    return {
      loading: false,
      eneKindList:[],
      rpt:'',      
      reportName:'',
      basicUnitList:[],
      basicUnit:'',
      dateFrom:moment(),
      dateTo:moment(),
      kind:'3',
      data:null,
      columns:null,
      pagination:false,
      xscroll: { x: 700 },
    };
  },
  computed: {
    
  },
  activated(){
    console.log("activated",this.$store.getters.clientId);
    if(this.$store.getters.clientId){
        console.log("activated load data");
        this.columns = [];
        this.data = [];
        this.initPage();
    }
  },
  mounted() { 
    //this.initPage();
  },
  beforeDestroy() {
   
  },
  methods:{
    disabledStartDate(startValue) {
      const endValue = this.dateTo;
      if (!startValue || !endValue) {
        return false;
      }


      return (startValue.valueOf() > endValue.valueOf() 
      || startValue > moment().endOf('month'));
    },
    disabledEndDate(endValue) {
      const startValue = this.dateFrom;
      if (!endValue || !startValue) {
        return false;
      }
     return (startValue.valueOf() >= endValue.valueOf() 
     || endValue > moment().endOf('month') );
    },
    handleSMonthChange(date,dateStr){
      const endValue = this.dateTo;
      if(endValue){
        console.log('dateTo - dateFrom',endValue.diff(date,'month'));
        if(endValue.diff(date,'month')>=12){
          this.dateFrom = moment();
          this.dateTo   = moment();
          this.$message.error(this.$t('energy.enedata056'));
          return;
        }
      }
    },
    handleEMonthChange(date,dateStr){
     const startValue = this.dateFrom;
      if(startValue){
        console.log('dateTo - dateFrom',date.diff(startValue,'month'));
        if(date.diff(startValue,'month')>=12){
          this.dateFrom = moment();
          this.dateTo   = moment();
          this.$message.error(this.$t('energy.enedata056'));
          return;
        }
      }
    },
    csvout(){
        let params =  {
            action:1,
            clientId: this.$store.getters.clientId,
            siteId: this.$route.query.id,
            sitegrId: this.$store.getters.sitegrId,
            tenantId: 0,
            reportType:this.rpt,
            dateFrom:this.dateFrom.format('YYYY/MM'),
            dateTo:this.dateTo.format('YYYY/MM'),
            basicUnit:this.basicUnit,
        };

        if(this.kind==='1'){
            params.clientId=this.$store.getters.clientId;
            params.siteId=0;
            params.sitegrId=0;
        }else if(this.kind==='2'){
            params.clientId=this.$store.getters.clientId;
            params.sitegrId=this.$store.getters.sitegrId;
            params.siteId=0;
        }

        console.log("downloadEnergyCSVDataWithURL Params",params);
        this.loading = true;

        downloadEnergyCSVDataWithURL('bivale/energy/data/analysis/building/download',params)
        .then((res) => {
            let name  = (this.$store.getters.siteServiceTypeName?this.$store.getters.siteServiceTypeName+'-':'')+this.reportName+'-';
            name += moment().format('YYYYMMDDHHmmssSSS')+'.zip';
            console.log("downloadEnergyCSVDataWithURL Result",name);
            downloadUrl(res,name);
        })
        .catch((err) => {
            this.loading = false;
            console.log(err);
        })
        .finally(()=>{
            this.loading = false;
        });
    },
    rptChange(e){
      console.log('rptChange',e);
      let t = parseInt(e.target.value);
      this.reportName = this.eneKindList[t-1].text;
      this.columns = [];
      this.data = [];
    //   this.drawDefultChart();
    //   this.co2Type = this.co2TypeList[0].no;
    //   switch(t){
    //       case 1:
    //       break;
    //       case 2:
    //       break;
    //       case 3:
    //       break;
    //       case 4:
    //           this.leveling = this.levelingTypeList[0].no;
    //           this.basicUnit = this.basicUnitList[0].no;
    //       break;
    //       case 5:
    //           this.leveling = this.levelingTypeList[0].no;
    //           this.basicUnit = this.basicUnitList[0].no;
    //       break;
    //   }
    },
    search(){
        let params =  {
            action:1,
            clientId: this.$store.getters.clientId,
            siteId: this.$route.query.id,
            sitegrId: this.$store.getters.sitegrId,
            tenantId: 0,
            reportType:this.rpt,
            dateFrom:this.dateFrom.format('YYYY/MM'),
            dateTo:this.dateTo.format('YYYY/MM'),
            basicUnit:this.basicUnit,
        };

        if(this.kind==='1'){
            params.clientId=this.$store.getters.clientId;
            params.siteId=0;
            params.sitegrId=0;
        }else if(this.kind==='2'){
            params.clientId=this.$store.getters.clientId;
            params.sitegrId=this.$store.getters.sitegrId;
            params.siteId=0;
        }
        console.log("GetEnergyAnalysisBuildData Params",params);
        this.loading = true;

        getEnergyAnalysisBuildData(params)
        .then((res) => {
            console.log("GetEnergyAnalysisBuildData Result",res);
            if(res.errorCode == '00'){
                if(res.data.err == -1){
                    this.$message.error(this.$t('energy.enedata065'));
                }else if(res.data.err == -2){
                    this.$message.error(this.$t('energy.enedata066'));
                }else{
                    this.columns = res.data.columnList;
                    this.data    = res.data.bodyList;
                }
            }
           
        })
        .catch((err) => {
            this.loading = false;
            console.log(err);
        })
        .finally(()=>{
            this.loading = false;
        });
    },
    basicUnitChange(e){
        console.log('basicUnitChange',e);
        this.search();
    },
    kindChanges(){
      this.columns = [];
      this.data = [];
    },
    initPage(){
      let params =  {
            action:0,
            clientId: this.$store.getters.clientId,
            siteId: this.$route.query.id,
            sitegrId: this.$store.getters.sitegrId,
            tenantId: 0
        };
        console.log("InitPage Params",params);
        this.loading = true;
        getEnergyAnalysisBuildData(params)
        .then((res) => {
            console.log("LoadData Result",res);
            if(res.errorCode == '00'){
                this.eneKindList			 =	res.data.eneKindList;
                this.rpt					 =	res.data.eneKindList[0].no;
                this.reportName              =  res.data.eneKindList[0].text;
                for (let i = 1; i < res.data.basicUnitList.length; i++) {
                    this.basicUnitList.push(res.data.basicUnitList[i]);
                    
                }
                this.basicUnit               =	res.data.basicUnitList[1].no;
            } 
            this.loading = false;
        })
        .catch((err) => {
            this.loading = false;
            console.log(err);
        });
    }
    
  },
  destroyed(){
   
  },
  components: {
    
  },
};
</script>

<style scoped>

#energyAnalysisBuildData {
  overflow: auto;
  width: 100%;
  height: 100%;
  padding: 10px 20px;
  font-family:apple-system, BlinkMacSystemFont, 'Segoe UI','PingFangSC-Regular', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'
}
.header-title {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 18px;
}
.header-title::before {
  content: "";
  display: inline-block;
  width:  4px;
  height: 30px;
  margin-right: 20px;
  background-color: #7682ce;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 10px;
}
.header-left {
  display: flex;
  justify-content: flex-start;
}
.header-condition {
  font-size: 14px;
  display: flex;
  flex-direction: column;
  margin-right: 10px;
}
.main{
    width: 100%;
    height:calc(100%-200px);
}
.footer {
  display: flex;
  justify-content:space-between;
  height: 52px;
  padding: 10px 10px;
  box-shadow: 0px 0px 10px #bdbcbc;
}

button {
   margin-left: 15px;
}

</style>


